import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { Router } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import {
  AuthProvider,
  AuthManagers,
  Authenticated,
  Gatekeeper,
  AuthReducer,
  createMiddleware,
  // @ts-ignore
} from 'kubra-lib-react-auth';
import appPermissions from '../../../config/permissions';
import { getRootReducerWith } from '../../../reducers';
import createApiMiddleware from '../../../middleware/api';
import { ForgeThemeProvider } from 'kubra-ux-forge';


const history = createHistory();

const authManager = new AuthManagers.Auth0({});

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  getRootReducerWith({
    auth: AuthReducer,
  }),
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      createMiddleware(authManager),
    ),
  ),
);

export default (appComponent: FunctionComponent, containerId = 'kubra-chat'): void => {
  ReactDOM.render(
    <Provider store={store}>
      <ForgeThemeProvider>
        <AuthProvider
          authManagerInstance={authManager}
          store={store}
        >
          <Authenticated>
            <Gatekeeper
              conditions={appPermissions}
              pass={(): JSX.Element => (
                <Router history={history}>
                  { React.createElement(appComponent) }
                </Router>
              )}
              fail={(): JSX.Element => (
                <h2>{'You don\'t have permissions to access this application'}</h2>
              )}
            />
          </Authenticated>
        </AuthProvider>
      </ForgeThemeProvider>
    </Provider>,
    document.getElementById(containerId),
  );
};
