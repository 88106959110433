import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledCloseIcon = styled.button`
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 2.4rem;
      svg {
        color: ${theme('forgeLib.neutral.100')};
        margin-right: 1.75rem;
      }
`