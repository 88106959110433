import React, { useState } from 'react';
import { ChatContainer } from '../ChatContainer/ChatContainer';
import { TrustContainer } from '../TrustContainer/TrustContainer';
import { StyledCTAButton, StyledCTAIcon, StyledCTAText } from './styledComponents';

export interface CTAProps {
  chatWindowOpen?: boolean;
  trustWindowOpen?: boolean;
  toggleChatWindow: (forceChatWindowOpen?: boolean) => void,
  buttonContent?: JSX.Element;
  buttonContentColor?: string;
  buttonBackgroundColor?: string;
  hoverContent?: string;
  hoverContentColor?: string;
  hoverBackgroundColor?: string;
  headerIcon?: JSX.Element;
  headerIconColor?: string;
  headerColor?: string;
  headerBackgroundColor?: string;
  chatTitle?: string;
  botChatIcon?: JSX.Element;
  agentToken: string;
  apiBaseUrl: string;
  trustText?: string;
  ftuBannerText?: string;
  bannerEverySession?: boolean;
  sessionTimeoutMs?: number;
}

export const CTAButton = ({
  chatWindowOpen,
  trustWindowOpen,
  buttonContent,
  buttonContentColor,
  buttonBackgroundColor,
  hoverContent,
  hoverContentColor,
  hoverBackgroundColor,
  headerIcon,
  headerIconColor,
  headerColor,
  headerBackgroundColor,
  chatTitle,
  botChatIcon,
  toggleChatWindow,
  agentToken,
  apiBaseUrl,
  trustText,
  ftuBannerText,
  bannerEverySession,
  sessionTimeoutMs
}: CTAProps): JSX.Element => {
  const [ showText, setShowText] = useState(false);
  const configuredText = hoverContent || `Let's Chat`;

  return (
    <>
      <StyledCTAButton
        tabIndex={0}
        onClick={(e) => { toggleChatWindow(); }}
        onKeyDown={(e) => { toggleChatWindow(); }}
        onMouseEnter={() => setShowText(true) }
        onMouseLeave={() => setShowText(false) }
        iconColor={buttonContentColor}
        iconBackgroundColor={buttonBackgroundColor}
        textColor={hoverContentColor}
        textBackgroundColor={hoverBackgroundColor}
      >
        {
          showText ? <StyledCTAText>{configuredText}</StyledCTAText> : <StyledCTAIcon>{buttonContent}</StyledCTAIcon>
        }
      </StyledCTAButton>
      {chatWindowOpen && (
        <ChatContainer
          toggleChatWindow={toggleChatWindow}
          agentToken={agentToken}
          apiBaseUrl={apiBaseUrl}
          headerIcon={headerIcon}
          headerIconColor={headerIconColor}
          headerColor={headerColor}
          headerBackgroundColor={headerBackgroundColor}
          chatTitle={chatTitle}
          botChatIcon={botChatIcon}
          ftuBannerText={ftuBannerText}
          bannerEverySession={bannerEverySession}
          sessionTimeoutMs={sessionTimeoutMs}
        />
      )}{trustWindowOpen && (
        <TrustContainer
          toggleTrustWindow={toggleChatWindow}
          trustText={trustText}
        />
      )}
    </>
  );
};

export default CTAButton;
