import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledTrustIcon = styled.span`
    display: flex;
    justify-content: center;
    margin: 3.5rem 0 2rem 0;
    svg {
      font-size: 40px;
      color: ${theme('forgeLib.secondary.40')};
    }
`