const {
  location,
} = window;

export const getOrigin = (): string => {
  return `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ''}`;
}

export const getBaseUrl = (domain: string): string => {
  return `https://${domain}`;
}

export const DEV_API_BASE_DOMAIN = 'dev.kubra.io'
export const QA_API_BASE_DOMAIN = 'qa.kubra.io'
export const PROD_API_BASE_DOMAIN = 'kubra.io'

export const getApiBaseUrl = (): string => {
  switch (location.hostname) {
    case QA_API_BASE_DOMAIN:
      return getBaseUrl(QA_API_BASE_DOMAIN)
    case PROD_API_BASE_DOMAIN:
      return getBaseUrl(PROD_API_BASE_DOMAIN)
    case DEV_API_BASE_DOMAIN:
    default:
      return getBaseUrl(DEV_API_BASE_DOMAIN)
  }
}

export default getOrigin;