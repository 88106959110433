import styled from 'styled-components';

export const StyledChatMessage = styled.div`
    &.user-chatbubble {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        width: 27rem;
        min-height: 4.4rem;
        padding: 1.6rem;
        min-width: 28rem;
        max-width: 27.6rem;
        margin: 0 calc(8rem - var(--scrollbar-width)) 1.6rem 3rem;
        background-color: #D5F4FF;
        color: #36434E;
        border-radius: 2.4rem 2.4rem 0px 2.4rem;
        font-size: 14px;
    }
    &.bot-chatbubble {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        width: 100%;
        margin: 0 calc(1.4rem - var(--scrollbar-width)) 3rem 0;
        min-height: 4.4rem;
        padding: 1.6rem;
        background-color: #DDFAF3;
        color: #36434E;
        border-radius: 2.4rem 2.4rem 2.4rem 0px;
        font-size: 14px;
    }
`