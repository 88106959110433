import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledBotChatIcon = styled.span`
    margin: 0 1rem 3rem 2rem;
    display: flex;
    align-items: flex-end;
    svg {
        color: ${theme('forgeLib.secondary.40')};
        height: 2.8rem;
        width: 2.6rem;
    }
`