import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledChatWindowContainer = styled.div`
  position: fixed;
  right: 3vh;
  bottom: 4vh;
  width: 34rem;
  height: 60rem;
  border-radius: 2.4rem;
  background-color: ${theme('forgeLib.neutral.100')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0 1.6rem 3.2rem rgba(0, 0, 0, 0.15);
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  z-index: 9999;
`