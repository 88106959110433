import styled from 'styled-components';
import { theme } from 'styled-tools';
import { TextButton } from 'kubra-ux-forge';

export const StyledTrustButtonDeny = styled(TextButton)`
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme('forgeLib.neutral.100')};
    color: ${theme('forgeLib.primary.50')};
    margin-bottom: 3.8rem;
    font-weight: 50rem;
    font-size: 1.4rem;
`