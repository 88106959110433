import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledChatInput = styled.textarea`
    display: flex;
    align-items: center;
    width: 28.2rem;
    height: 4.1rem;
    max-height: 13.4rem;
    border: 0.1rem solid ${theme('forgeLib.neutral.100')};
    outline: none;
    resize: none;
    padding: 1rem 0 0 1.5rem;
    margin: 0 4rem 0 0.8rem;
    text-align: left;
    overflow-y: scroll;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    &::-webkit-scrollbar {
        background: transparent;
    }
    &::placeholder {
        color: ${theme('forgeLib.neutral.80')};
    }
`