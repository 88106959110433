import styled from 'styled-components';

export const StyledChatBubbleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    overflow-x: hidden;
    --scrollbar-width: (340px - 100%);
    &::-webkit-scrollbar {
      background: transparent;
    }
`

export const StyledUserChatBubbleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: calc(340px - var(--scrollbar-width));
`

export const StyledUserChatContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 270px;
    min-height: 44px;
    padding: 15px 20px 15px 20px;
    margin-right: calc(340px - var(--scrollbar-width));
    margin-left: 16px;
    margin-bottom: 30px;
    background-color: #D5F4FF;
    color: #36434E;
    border-radius: 24px 24px 0px 24px;
    font-size: 14px;
`

export const StyledBotChatBubbleContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    width: calc(340px - var(--scrollbar-width));
`

export const StyledBotChatContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    width: 100%;
    margin-right: calc(14px - var(--scrollbar-width));
    min-height: 44px;
    padding: 20px 20px 20px 27px;
    margin-bottom: 30px;
    background-color: #DDFAF3;
    color: #36434E;
    border-radius: 24px 24px 24px 0px;
    font-size: 14px;
`