import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledBannerContainer = styled.div`
    position: fixed;
    margin-top: 6.4rem;
    display: flex;
    align-items: center;
    height: auto;
    max-height: 7.1rem;
    width: 34rem;
    line-height: 1.35rem;
    overflow: hidden;
    text-overflow: clip;
    &.banner-bg-error {
        background-color: #DD3227;
        color: ${theme('forgeLib.neutral.100')};
    }
    &.banner-bg-positive {
        background-color: #01A457;
    }
`