import React, { useState } from 'react';
import { CTAButton  }from './CTA/CTAButton';
import { isTrustValueSet } from './helpers/Helpers';
import { StyledConfiguredHeaderIcon } from './StyledConfiguredHeaderIcon';
import { getApiBaseUrl } from '../lib/origin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faRobot } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
library.add(far);
library.add(fas as any);

export interface KubraChatWidget {
  agentToken: string;
  apiBaseUrl?: string;
  buttonContent?: string | string[];
  buttonContentType?: ButtonContentType;
  buttonContentColor?: string;
  buttonBackgroundColor?: string;
  hoverContent?: string;
  hoverContentColor?: string;
  hoverBackgroundColor?: string;
  headerIcon?: string | string[];
  headerIconType?: HeaderIconType;
  headerIconColor?: string;
  headerColor?: string;
  headerBackgroundColor?: string;
  chatTitle?: string;
  botChatIcon?: string | string[];
  botChatIconType?: string;
  trustText?: string;
  ftuBannerText?: string;
  bannerEverySession?: boolean;
  sessionTimeoutMs?: number;
}
export type ButtonContentType = "FontAwesomeIconName" | "ImgUrl";
export type HeaderIconType = "FontAwesomeIconName" | "ImgUrl";
export type BotChatIconType = "FontAwesomeIconName" | "ImgUrl";
export interface AppProps {
  kubraChatWidget?: KubraChatWidget;
}

// globally declare the kubraChatWidget interface; it's externally required
// to be set before running this app
declare global {
  interface Window {
    kubraChatWidget: KubraChatWidget | undefined;
  }
}



export const toggleChatWindow = (
  isChatWindowOpen: boolean,
  setIsChatWindowOpen: React.Dispatch<React.SetStateAction<boolean>>,
  isTrustWindowOpen: boolean,
  setIsTrustWindowOpen: React.Dispatch<React.SetStateAction<boolean>>,
  forceChatWindowOpen: boolean,
): void => {
  const isTrustedValueSet = isTrustValueSet();
    if (isTrustedValueSet) {
      setIsChatWindowOpen(!isChatWindowOpen);
      return;
    }
    // condition that occurs only when first setting the trust:
    // trust value is unset, but the chat window doesn't open
    // after trusting (via the logic above)
    if (forceChatWindowOpen) {
      setIsChatWindowOpen(true);
      setIsTrustWindowOpen(false);
      return;
    }

    setIsTrustWindowOpen(!isTrustWindowOpen);
}

export const App = (props?: AppProps): JSX.Element => {
  if (props) {
    if (props?.kubraChatWidget) {
      /* eslint-disable-next-line no-var */
      window.kubraChatWidget = { ...props.kubraChatWidget};
    }
  }

  // window.X is globally scoped and works the same way as just referring
  // to kubraChatWidget on its own
  if (!window.kubraChatWidget) {
      throw "KUBRA Chat Widget is not defined: please define kubraChatWidget"
  }

  const [ isChatWindowOpen, setIsChatWindowOpen ] = useState(false);
  const [ isTrustWindowOpen, setIsTrustWindowOpen ] = useState(false);

  // this is wrapped around a function that exists outside of
  // this react component to allow it to be unit testable
  const toggleChatWindowWrapper = (forceChatWindowOpen = false) => {
    toggleChatWindow(
      isChatWindowOpen,
      setIsChatWindowOpen,
      isTrustWindowOpen,
      setIsTrustWindowOpen,
      forceChatWindowOpen,
    )
  }

  let buttonContent: JSX.Element = <FontAwesomeIcon icon={faRobot}/>;
  let headerIcon: JSX.Element = <FontAwesomeIcon icon={faRobot}/>;
  let botChatIcon: JSX.Element = <FontAwesomeIcon icon={faRobot} />

  switch(window.kubraChatWidget.buttonContentType) {
    case "FontAwesomeIconName":
      buttonContent = <FontAwesomeIcon icon={window.kubraChatWidget.buttonContent || 'robot'}/>;
      break;
    case "ImgUrl":
      let imgSrc = '';
      if (Array.isArray(window.kubraChatWidget.buttonContent)) {
        imgSrc = window.kubraChatWidget.buttonContent[0];
      } else {
        if (!window.kubraChatWidget.buttonContent) break;
        imgSrc = window.kubraChatWidget.buttonContent;
      }
      buttonContent = <img src={imgSrc} alt="chat button icon" height="24px" width="24px" />;
      break;
    default:
      break;
  }

  switch(window.kubraChatWidget.headerIconType) {
    case "FontAwesomeIconName":
      headerIcon = <FontAwesomeIcon icon={window.kubraChatWidget.headerIcon || 'robot'}/>;
      break;
    case "ImgUrl":
      let imgSrc = '';
      if (Array.isArray(window.kubraChatWidget.headerIcon)) {
        imgSrc = window.kubraChatWidget.headerIcon[0];
      } else {
        if (!window.kubraChatWidget.headerIcon) break;
        imgSrc = window.kubraChatWidget.headerIcon;
      }
      headerIcon = <img src={imgSrc} alt="header icon" />;
      break;
    default:
      break;
  }

  switch(window.kubraChatWidget.botChatIconType) {
    case "FontAwesomeIconName":
      botChatIcon = <FontAwesomeIcon icon={window.kubraChatWidget.botChatIcon || 'robot'}/>;
      break;
    case "ImgUrl":
      let imgSrc = '';
      if (Array.isArray(window.kubraChatWidget.botChatIcon)) {
        imgSrc = window.kubraChatWidget.botChatIcon[0];
      } else {
        if (!window.kubraChatWidget.botChatIcon) break;
        imgSrc = window.kubraChatWidget.botChatIcon;
      }
      botChatIcon = <img src={imgSrc} alt="bot chat icon" />;
      break;
    default:
      break;
  }

  return (
    <div>
      <CTAButton
        chatWindowOpen={isChatWindowOpen}
        trustWindowOpen={isTrustWindowOpen}
        toggleChatWindow={toggleChatWindowWrapper}
        agentToken={window.kubraChatWidget.agentToken}
        apiBaseUrl={window.kubraChatWidget.apiBaseUrl || getApiBaseUrl()}
        buttonContent={buttonContent}
        buttonContentColor={window.kubraChatWidget.buttonContentColor}
        buttonBackgroundColor={window.kubraChatWidget.buttonBackgroundColor}
        hoverContent={window.kubraChatWidget.hoverContent}
        hoverContentColor={window.kubraChatWidget.hoverContentColor}
        hoverBackgroundColor={window.kubraChatWidget.hoverBackgroundColor}
        headerIcon={headerIcon}
        headerIconColor={window.kubraChatWidget.headerIconColor}
        headerColor={window.kubraChatWidget.headerColor}
        headerBackgroundColor={window.kubraChatWidget.headerBackgroundColor}
        chatTitle={window.kubraChatWidget.chatTitle}
        botChatIcon={botChatIcon}
        trustText={window.kubraChatWidget.trustText}
        ftuBannerText={window.kubraChatWidget.ftuBannerText}
        bannerEverySession={window.kubraChatWidget.bannerEverySession}
        sessionTimeoutMs={window.kubraChatWidget.sessionTimeoutMs}
      />
    </div>
  );
};

export default App;
