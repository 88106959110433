// eslint-disable-next-line import/named
import { combineReducers, Reducer } from 'redux';

const rootReducers = {
  // place root reducers here
  // empty: (state: any[] = [], action: string) => { return state },
};

/**
 * A function that will use combineReducers to merge additional reducers with the root reducer
 * @param {*} additionalReducers
 */

export const getRootReducerWith = (additionalReducers = {}): Reducer => combineReducers({
  ...rootReducers,
  ...additionalReducers,
});

export default combineReducers(rootReducers);
