import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledTrustPromptContainer = styled.div`
  position: fixed;
  right: 3vh;
  bottom: 4vh;
  width: 34rem;
  height: 42.4rem;
  border: .1rem solid ${theme('forgeLib.neutral.95')};
  border-radius: 4.5rem;
  background-color: ${theme('forgeLib.neutral.100')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  z-index: 9999;
`