import { object, string, array, boolean } from 'yup';
import yup from 'yup'

const quickReply = object({
    title: string().required(),
    quickReplies: array().of(string()).required(),
})

const richMessage = object({
    quickReplies: quickReply.required(),
})

const richResponse = object({
    source: string().required(),
    richMessages: array().of(richMessage).required(),
})

export const chatResponse = object({
    fulfillmentText: string().defined(),
    richResponses: array().of(richResponse).optional(),
})

export interface ChatResponse extends yup.InferType<typeof chatResponse>{
}

export const chatMessage = object({
    msgText: string(),
    isUserMsg: boolean().required(),
    quickReplies: array().of(string()).optional(),
})

export interface ChatMessage extends yup.InferType<typeof chatMessage>{}

export const getQuickReplies = (chatResponse: ChatResponse): string[] => {
    const result: string[] = [];

    if (!chatResponse?.richResponses) return result;

    if (chatResponse.richResponses.length < 1) return result;
    if (chatResponse.richResponses[0].richMessages.length < 1) return result;
    if (chatResponse.richResponses[0].source !== 'iqchat') return result;
    if (chatResponse.richResponses[0].richMessages[0].quickReplies.quickReplies.length < 1) return result;

    const allQuickReplies: (string | undefined)[] = chatResponse.richResponses[0].richMessages[0].quickReplies.quickReplies;

    for (let i = 0; i < Math.min(allQuickReplies.length, 4); i++) {
        if (!allQuickReplies[i]) continue;
        result.push(allQuickReplies[i] as string);
    }

    return result;
}
