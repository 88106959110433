import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledChatWindowHeaderContainer = styled.div <{ headerBackgroundColor?: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${theme('forgeLib.secondary.40')};
    background: ${props => props.headerBackgroundColor || '#007FAD'};
    min-height: 6.4rem;
    width: 34rem;
    border-radius: 2.4rem 2.4rem 0 0;
`