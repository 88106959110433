import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { v4 as uuid } from 'uuid';
import axios from 'axios';
import xss from 'xss';
import parse from 'html-react-parser';
import '../../sass/components/ChatContainer/_ChatContainer.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  isTrusted,
  getSessionStorageContactId,
  getLocalStorageContactId,
  setLocalStorageContactId,
  setSessionStorageContactId,
} from '../helpers/Helpers';
import { StyledChatWindowContainer } from './styledComponents';
import {
  StyledChatWindowHeaderContainer,
  StyledCloseIcon,
  StyledHeaderIconContainer,
  StyledHeaderText,
} from './styledComponents/Header';
import {
  StyledBannerContainer,
  StyledBannerText,
  StyledFTUBannerContainer,
  StyledFTUBannerText,
} from './styledComponents/Banners';
import {
  StyledBotChatIcon,
  StyledBotChatIconContainer,
  StyledChatBubbleContainer,
  StyledChatMessage,
  StyledChatMessageContainer,
  StyledQRButton,
  StyledQRButtonText,
  StyledQRButtonsContainer,
  StyledUserChatBubbleContainer,
} from './styledComponents/ChatBubble';
import {
  StyledChatFooterContainer,
  StyledChatInput,
  StyledChatInputContainer,
  StyledSendIcon,
} from './styledComponents/Footer'
import { StyledBotChatBubbleContainer, StyledBotChatContent, StyledUserChatContent } from './styledComponents/ChatBubble/StyledChatBubbleContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChatMessage, ChatResponse, getQuickReplies, chatResponse as chatResponseSchema } from '../helpers/chat';
import { faRobot, faChevronDown, faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';

/**
 * The welcome banner will be hidden after this amount of time elapses.
 */
const WELCOME_BANNER_TIMEOUT_MS = 10000;
export const CLOSE_CHAT_WINDOW_TITLE = 'Close chat window';
export const CHAT_INPUT_TITLE = 'Chat input';
export const CHAT_INPUT_ID = 'input-text';
export const MAX_MSG_LENGTH = 250;
export const MAX_FTU_TEXT_LENGTH = 250;
export const INVALID_CHAT_MSG = `Message is too long. Shorten to less than ${MAX_MSG_LENGTH} characters and re-send.`;

export interface ChatContainerProps {
  chatWindowOpen?: boolean;
  toggleChatWindow: (forceChatWindowOpen?: boolean) => void,
  chatTitle?: string;
  headerIcon?: JSX.Element;
  headerIconColor?: string;
  botChatIcon?: JSX.Element;
  botGreeting?: string;
  inputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ftuBannerText?: string;
  agentToken: string;
  apiBaseUrl: string;
  headerColor?: string;
  headerBackgroundColor?: string;
  bannerEverySession?: boolean;

  /**
   * Every time a message is sent (and when the initial session is established)
   * this number indicates how long (in milliseconds) it will take for the session
   * to be cleared out.
   */
  sessionTimeoutMs?: number;
}

export const isInvalidChatMessage = (message: string): string => {
  if (message?.length > MAX_MSG_LENGTH) return INVALID_CHAT_MSG;
  return '';
};

export const defaultFtuBannerText = `This chatbot provides 24/7 service for tasks such as reporting outages, making payments, and answering frequently asked questions.`;

// Checks that ftuBannerText does not exceed 250 characters,
// and also makes sure that a default value if there
// isn't a value provided from the passed-in props. If it exceeds 250 characters,
// return an empty string.
export const validateFtuBannerText = (ftuBannerText: string | any = defaultFtuBannerText): string => {
  if (typeof ftuBannerText === 'string') {
    if (ftuBannerText.length > MAX_FTU_TEXT_LENGTH) return '';
    return ftuBannerText;
  }
  return defaultFtuBannerText;
}

const CHAT_SESSION_TIMEOUT_DEFAULT_MS = 1200000; // 1000 * 60 * 20; // 20 minutes

enum BannerStatus {
  Positive = 'positive',
  Error = 'error',
}

export const ChatContainer = ({
  toggleChatWindow,
  headerIconColor,
  chatTitle,
  headerIcon,
  botChatIcon,
  ftuBannerText,
  agentToken,
  apiBaseUrl,
  headerColor,
  headerBackgroundColor,
  bannerEverySession,
  sessionTimeoutMs,
}: ChatContainerProps): JSX.Element => {
  const configuredHeader = chatTitle || `Hello, I'm Bot!`;
  const configuredHeaderIcon = headerIcon || <FontAwesomeIcon icon={faRobot} />
  const configuredBotChatIcon = botChatIcon || <FontAwesomeIcon icon={faRobot} />
  const configuredFtuBannerText = validateFtuBannerText(ftuBannerText);
  const sessionTimeoutMsValue = sessionTimeoutMs || CHAT_SESSION_TIMEOUT_DEFAULT_MS;

  const lastMessageTimestamp = useRef(new Date());
  const [ initialMessageSent, setInitialMessageSent ] = useState(false);
  const [ isChatView, setIsChatView ] = useState(true);
  const [ inputText, setInputText ] = useState('');
  const [ localContactId, setLocalContactId ] = useState(window.localStorage.getItem('useLocal'));
  const [ sessionContactId, setSessionContactId ] = useState(window.sessionStorage.getItem('contactId'))
  const [ ftuBannerHidden, setFtuBannerHidden ] = useState(false /* checkValueFromLocalStorage(FTU_BANNER_STORAGE_KEY) */);
  const [ showBanner, setShowBanner ] = useState(false);
  const [ bannerChildren, setBannerChildren ] = useState(<></>);
  const [ bannerStatus, setBannerStatus ] = useState(BannerStatus.Positive);
  const [ isSendingMessage, setSendingMessage ] = useState(false);
  const defaultChatMessages: ChatMessage[] = [];
  const [ chatMessages, setChatMessages ] = useState(defaultChatMessages);

 /**
   * Hides the welcome banner.
   */
 const welcomeBannerTimeout = useCallback(() => {
    setFtuBannerHidden(true);
  }, [setFtuBannerHidden]);

  /**
   * The welcome banner is always shown to the user when they first open the page.
   * The banner should be hidden after a certain amount of time.
   */
  const welcomeBannerTimer = useRef<NodeJS.Timeout>(setTimeout(welcomeBannerTimeout, WELCOME_BANNER_TIMEOUT_MS));

  /**
   * Use this timer function when resetting the `sessionTimeoutTimer` ref.
   */
  const sessionTimeout = useCallback(() => {
    const now = new Date();
    if (lastMessageTimestamp.current && now.getTime() - lastMessageTimestamp.current.getTime() > sessionTimeoutMsValue) {
      setBannerChildren(
        <StyledBannerText>
          You{"'"}ve been away for a few minutes and your session has expired. <Link to='' onClick={() => {location.reload()}}>Start over</Link>
        </StyledBannerText>
      );
      setBannerStatus(BannerStatus.Positive);
      setShowBanner(true);
      setChatMessages([]);
    }
  }, [lastMessageTimestamp, setBannerChildren, setBannerStatus, setShowBanner, setChatMessages, sessionTimeoutMsValue]);

  /**
   * This timer needs to be reset every time the user sends a message so that
   * their session doesn't expire while they're actively using the chatbot.
   */
  const sessionTimeoutTimer = useRef<NodeJS.Timeout>(setTimeout(sessionTimeout, sessionTimeoutMsValue));

  const getContactId = (): string => {
    const trustedBrowser = isTrusted();

    if (trustedBrowser) {
      const contactId = getLocalStorageContactId();
      if (contactId) {
        return contactId;
      } else {
        const uniqueId = uuid();
        setLocalStorageContactId(uniqueId);
        return uniqueId;
      }
    } else {
      const contactId = getSessionStorageContactId();
      if (contactId) {
        return contactId;
      } else {
        const uniqueId = uuid();
        setSessionStorageContactId(uniqueId);
        return uniqueId;
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter') {
      // if there was an error, we need to choose not to clear out the user's text box
      sendChatMessage().then((newInputTextValue: string) => {
        setInputText(newInputTextValue || '');
      });

      event.preventDefault();
      event.stopPropagation();
    }
  }

  const addChatMessage = useCallback((msgText: string, isUserMsg: boolean, manualNewChatMessages?: any[] | undefined, quickReplies?: string[]): ChatMessage[] => {
    // optionally pass in manualNewChatMessages because react hooks won't actually update
    // the value of chatMessages if this function gets called more than once in the same function
    const newChatMsg: ChatMessage = {
      msgText: msgText,
      isUserMsg: isUserMsg,
    };

    if (quickReplies) {
      newChatMsg.quickReplies = [...quickReplies];
    }

    let newChatMessages = [newChatMsg, ...chatMessages];
    if (manualNewChatMessages) {
      newChatMessages = [newChatMsg, ...manualNewChatMessages];
    }
    setChatMessages(newChatMessages);
    return newChatMessages;
  }, [chatMessages, setChatMessages]);

  const sendMessage = useCallback(async (messageToSend: string, showMessageInHistory = true): Promise<string> => {
    // in order to deal with react hooks not actually updating the
    // chatMessages array throughout this function, we keep track of
    // a local chat messages array right here, and then
    // push that array at the end of this function
    // the newText || inputText clause makes it so that
    // the chat history shows the original message that would be empty
    // after xss(inputText) is executed on it
    const currentChatMessages = showMessageInHistory
      ? addChatMessage(messageToSend || inputText, true)
      : [...chatMessages];


    if (!messageToSend) {
      const errMsg = 'Invalid input';
      addChatMessage(errMsg, false, currentChatMessages);
      return Promise.reject(errMsg);
    }

    const data = {
      "query": messageToSend,
      "channel": "IQCHAT",
      "contactId": getContactId(),
    }
    const options = {
      auth: {
        username: '',
        password: ''

      },
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*',
      }
    }

    setSendingMessage(true);
    setInputText('');

    try {
      const responseData = await axios.post(`${apiBaseUrl}/kubra-iq/iqchat?agentToken=${agentToken}`, data);

      setSendingMessage(false);

      if (!responseData?.data) {
        console.log('no response data received from chat message request', responseData);
      }

      const chatResponse = await chatResponseSchema.validate({ ...responseData?.data });

      addChatMessage(
        chatResponse.fulfillmentText,
        false,
        currentChatMessages,
        getQuickReplies(chatResponse)
      );

      // update the latest timestamp when sending a message so that we can
      // track when the user's session is expected to expire
      lastMessageTimestamp.current = new Date();

      // trigger a timeout after sending the first message to indicate that the user is logged out
      clearTimeout(sessionTimeoutTimer.current);
      sessionTimeoutTimer.current = setTimeout(sessionTimeout, sessionTimeoutMsValue);

      return '';
    } catch (err) {
      setSendingMessage(false);
      console.error(err);
      const errMsg = 'An error has occurred. Please try again.';
      // show an error banner
      setBannerChildren(
        <span>
          {errMsg}
        </span>
      );
      setBannerStatus(BannerStatus.Error);
      setShowBanner(true);
      return messageToSend;
    }
  }, [
    addChatMessage,
    setSendingMessage,
    setBannerChildren,
    setBannerStatus,
    setShowBanner,
    sessionTimeoutTimer,
    sessionTimeoutMsValue,
    sessionTimeout,
    inputText,
    chatMessages,
    agentToken,
    apiBaseUrl,
  ]);

  const sendChatMessage = async (): Promise<string> => {
    if (inputText === '') return Promise.resolve('');

    // parse input text first
    const newText = xss(
      inputText,
      {
        whiteList: {},
        stripIgnoreTag: true,
        // see docs here: https://github.com/leizongmin/js-xss#whitelist
        stripIgnoreTagBody: ['script'], // the script tag is a special case, we need
        // to filter out its content
      },
    ).trim();

    const isInvalidUserInput: string = isInvalidChatMessage(newText);
    if (isInvalidUserInput) {
      // show an error banner
      setBannerChildren(
        <span>
          {isInvalidUserInput}
        </span>
      );
      setBannerStatus(BannerStatus.Error);
      setShowBanner(true);
      return Promise.reject(isInvalidUserInput);
    }

    // clear out the error banner if the message is valid
    setBannerChildren(<></>)
    // setBannerStatus(BannerStatus.Positive);
    setShowBanner(false);

    return await sendMessage(newText);
  }


  const getChatInputHeight = (inputText: string): number => {
    // identify the number of newline characters
    const newLineCharacters = (inputText.match(/\n/g) || []).length;
    const minHeight = 41;
    const linesByAvgNumCharacters = Math.round(
      (inputText.length-newLineCharacters) / 50.0
    );
    const lineHeight = 16;
    return minHeight + (linesByAvgNumCharacters + newLineCharacters) * lineHeight;
  }

  // send an initial 'hello' message on page load
  useEffect(() => {
    if(!initialMessageSent && chatMessages.length === 0 && !isSendingMessage) {
      setInitialMessageSent(true);
      sendMessage('hello', false);
    }
  }, [chatMessages, sendMessage, isSendingMessage, initialMessageSent, setInitialMessageSent])

  return (
      <StyledChatWindowContainer>
        <StyledChatWindowHeaderContainer headerBackgroundColor={headerBackgroundColor}>
          <StyledHeaderIconContainer headerIconColor={headerIconColor}>
            {configuredHeaderIcon}
          </StyledHeaderIconContainer>
          <StyledHeaderText headerColor={headerColor}>{configuredHeader}</StyledHeaderText>
          <StyledCloseIcon
            tabIndex={0}
            onClick={(_e) => {
              toggleChatWindow();
            }}
            onKeyDown={(_e) => {
              toggleChatWindow();
            }}
            title={CLOSE_CHAT_WINDOW_TITLE}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </StyledCloseIcon>
        </StyledChatWindowHeaderContainer>
        { !ftuBannerHidden && configuredFtuBannerText && (
            <StyledFTUBannerContainer>
              <StyledFTUBannerText>
                {configuredFtuBannerText}
              </StyledFTUBannerText>
            </StyledFTUBannerContainer>
          )
        }
        { showBanner && bannerChildren && bannerStatus && (
            <StyledBannerContainer className={classNames(bannerStatus ? `banner-bg-${bannerStatus}` : '' )}>
              <StyledBannerText>
                {bannerChildren}
              </StyledBannerText>
            </StyledBannerContainer>
          )
        }
        <StyledChatBubbleContainer>
            {
              chatMessages.map((chatMsg, i, arr) => (
                <StyledChatMessageContainer className={chatMsg.isUserMsg ? "user-chatbubble-flex-parent" : "bot-chatbubble-flex-parent"} key={uuid()}>
                  <StyledBotChatIconContainer>
                    <StyledBotChatIcon key={uuid()}>
                      {
                        !chatMsg.isUserMsg ? configuredBotChatIcon : ''
                      }
                    </StyledBotChatIcon>
                    <StyledChatMessage className={chatMsg.isUserMsg ? "user-chatbubble" : "bot-chatbubble"}>
                      {chatMsg.msgText
                        && (
                          parse(chatMsg.msgText)
                        )
                      }
                      {
                        !chatMsg.isUserMsg && chatMsg.quickReplies && (
                          chatMsg.quickReplies.map(quickReply => (
                            <StyledQRButtonsContainer key={uuid()}>
                              <StyledQRButton
                                variant ="outline"
                                onClick={() => {
                                  if (quickReply) sendMessage(quickReply);
                                }}
                              >
                                {quickReply}
                              </StyledQRButton>
                            </StyledQRButtonsContainer>
                          ))
                        )
                      }
                    </StyledChatMessage>
                  </StyledBotChatIconContainer>
                </StyledChatMessageContainer>
            ))
            }
         </StyledChatBubbleContainer>
          { isChatView && (
              <StyledChatFooterContainer>
                <StyledChatInputContainer>
                  <StyledChatInput
                    aria-label={CHAT_INPUT_TITLE}
                    placeholder={isSendingMessage ? "Loading..." : "Type your question here"}
                    id={CHAT_INPUT_ID}
                    style={
                      {
                        height: `${getChatInputHeight(inputText)}px`,
                        maxHeight: `134px`,
                        overflowY: `auto`,
                      }
                    }
                    onChange={(e) => {
                      setInputText(e.target.value);
                    }}
                    value={inputText}
                    onKeyDown={handleKeyPress}
                  />
                  <StyledSendIcon
                    tabIndex={0}
                    onClick={(_e) => {
                      sendChatMessage().then((newInputTextValue: string) => {
                        setInputText(newInputTextValue || '');
                      });
                    }}
                    onKeyDown={(_e) => {
                      sendChatMessage().then((newInputTextValue: string) => {
                        setInputText(newInputTextValue || '');
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faPaperPlaneTop} />
                  </StyledSendIcon>
                </StyledChatInputContainer>
              </StyledChatFooterContainer>
          )}
      </StyledChatWindowContainer>
  );
};

export default ChatContainer;
