import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledChatFooterContainer = styled.div`
    width: 100%;
    background-color: ${theme('forgeLib.neutral.100')};
    box-shadow: 0 -0.2rem 0.4rem rgba(156, 156, 156, 0.25);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 0.1rem solid ${theme('forgeLib.neutral.100')};
    border-radius: 0 0 2.4rem 2.4rem;
`