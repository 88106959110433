import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledFTUBannerText = styled.span`
    display: flex;
    align-items: center;
    color: ${theme('forgeLib.neutral.100')};
    font-size: 1.1rem;
    line-height: 1.35rem;
    overflow: hidden;
`