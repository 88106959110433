const TRUSTED = "trusted";
const CONTACT_ID = "contactId";
const TRUSTED_TRUE = "true";
const TRUSTED_FALSE = "false";

/**
 * @returns `true` if the browser is marked by the user in local storage as `"trusted"`. `false` otherwise.
 */
export const isTrusted = (): boolean => {
  return window.localStorage.getItem(TRUSTED) === TRUSTED_TRUE;
}

/**
 * Returns `true` if the user has already determined if they do or do not
 * operate in a trusted browsing session.
 *
 * This does not return the actual trust value. It just determines
 * if the user has agreed or disagreed to trust in the first place.
 *
 * For determining trust, use `isTrusted`.
 */
export const isTrustValueSet = (): boolean => {
  if (isTrusted()) return true;
  if (window.sessionStorage.getItem(TRUSTED) === TRUSTED_FALSE) return true;
  return false;
}

/**
 * If the browser is trusted, `"trusted"` will be set to `"true"` in the
 * browsers local storage.
 *
 * Otherwise, the `"trusted"` local storage item will be deleted, and
 * a value of `"false"` will be set in session storage to prevent
 * to signal that the user has determined a trust value.
 */
export const setIsTrusted = (trustValue = true): void => {
  if (trustValue) {
    window.localStorage.setItem(TRUSTED, TRUSTED_TRUE);
  } else {
    window.localStorage.removeItem(TRUSTED);
    window.sessionStorage.setItem(TRUSTED, TRUSTED_FALSE)
  }
}

export const getLocalStorageContactId = (): string => {
  return window.localStorage.getItem(CONTACT_ID) || '';
};

export const getSessionStorageContactId = (): string => {
  return window.sessionStorage.getItem(CONTACT_ID) || '';
};

export const setLocalStorageContactId = (uniqueId: string): void => {
  window.localStorage.setItem(CONTACT_ID, uniqueId);
};

export const setSessionStorageContactId = (uniqueId: string): void => {
  window.sessionStorage.setItem(CONTACT_ID, uniqueId);
};
