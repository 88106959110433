import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Button } from "kubra-ux-forge";

export const StyledQRButton = styled(Button)`
    height: 3.2rem;
    padding: 0.4rem 3.2rem 0.4rem 3.2rem;
    border-radius: 5rem;
    border: 0.1rem solid ${theme('forgeLib.primary.50')};
    font-size: 1.6rem;
    font-weight: 500;
`