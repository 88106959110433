import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledTrustBody = styled.p`
    color: ${theme('forgeLib.secondary.40')};
    font-size: 1.4rem;
    padding-left: 4.9rem;
    padding-right: 4.9rem;
    margin-top: 0.1rem;
    text-align: center;
`