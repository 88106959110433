import styled from 'styled-components';

export const StyledSendIcon = styled.button`
    border: none;
    background: transparent;
    margin: 0 1.5rem 0 0;
        svg {
            color: #BFBFBF;
            height: 1.6rem;
            width: 1.6rem;
            cursor: pointer;
        }
`