
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';

import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';

import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';

import { Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';

import rootReducer from '../../../reducers';
import createApiMiddleware from '../../../middleware/api';
import { ForgeThemeProvider } from 'kubra-ux-forge';

const history = createBrowserHistory();

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      createApiMiddleware(),
    ),
  ),
);

/**
 * Export a function that takes an App component, and wraps it with the redux store provider and a
 * router before rendering
 */
export default (appComponent: FunctionComponent, containerId = 'kubra-chat'): void => {
  ReactDOM.render(
    <Provider store={store}>
      <ForgeThemeProvider>
        <Router history={history}>
          { React.createElement(appComponent) }
        </Router>
      </ForgeThemeProvider>
    </Provider>,
    document.getElementById(containerId),
  );
};
