import styled from 'styled-components';
import { theme } from 'styled-tools';
import { Button } from "kubra-ux-forge";

export const StyledTrustButtonConfirm = styled(Button)`
    cursor: pointer;
    border: solid .1rem ${theme('forgeLib.primary.50')};
    border-radius: 5rem;
    background-color: ${theme('forgeLib.primary.50')};
    color: ${'forgeLib.neutral.100'};
    margin-bottom: 3.8rem;
`