import React from 'react';
import { setIsTrusted } from '../helpers/Helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faRobot } from '@fortawesome/pro-solid-svg-icons';
import {
  StyledTrustBody,
  StyledTrustButtonConfirm,
  StyledTrustButtonDeny,
  StyledTrustButtonsContainer,
  StyledTrustCloseIcon,
  StyledTrustHeader,
  StyledTrustIcon,
  StyledTrustPromptContainer
} from './styledComponents';

export const TrustBtnYesTitle = "Yes - this device is trusted";
export const TrustBtnNoTitle = "No - this device is not trusted";
export const TrustBtnCloseTitle = "Close this prompt"

export interface TrustContainerProps {
  trustWindowOpen?: boolean;
  ctaPosition?: string;
  backgroundColor?: any;
  stylePosition?: any;
  title?: string;
  icon?: JSX.Element | undefined;
  trustText?: string;
  toggleTrustWindow: (forceChatWindowOpen?: boolean) => void;
}

export const TrustContainer = ({
  icon,
  trustText,
  toggleTrustWindow,
}: TrustContainerProps): JSX.Element => {

  const configuredIcon = typeof icon !== 'undefined' ? icon : <FontAwesomeIcon icon={faRobot} />;

  return (
    <StyledTrustPromptContainer>
      <StyledTrustCloseIcon
        tabIndex={0}
        title={TrustBtnCloseTitle}
        onClick={(e) => {
          toggleTrustWindow();
        }}
        onKeyDown={(e) => {
          toggleTrustWindow();
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </StyledTrustCloseIcon>
      <StyledTrustIcon>{ configuredIcon }</StyledTrustIcon>
      <StyledTrustHeader>Are you using a trusted device?</StyledTrustHeader>
      <StyledTrustBody>
        {trustText ? trustText : 'A trusted device is a device you use frequently, such as your personal mobile phone, tablet or home computer'}
      </StyledTrustBody>
      <StyledTrustButtonsContainer>
        <StyledTrustButtonConfirm
          title={TrustBtnYesTitle}
          onClick={(e) => {
            toggleTrustWindow(true);
            setIsTrusted();
          }}
        >
          Yes
        </StyledTrustButtonConfirm>
        <StyledTrustButtonDeny
          title={TrustBtnNoTitle}
          onClick={(e) => {
            toggleTrustWindow(true);
            setIsTrusted(false);
          }}
        >
          No
        </StyledTrustButtonDeny>
      </StyledTrustButtonsContainer>
    </StyledTrustPromptContainer>
  );
};

export default TrustContainer;
