import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledFTUBannerContainer = styled.div`
    position: fixed;
    margin-top: 6.4rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    display: flex;
    align-items: center;
    background-color: ${theme('forgeLib.success.50')};
    color: ${theme('forgeLib.neutral.100')};
    height: auto;
    max-height: 7.1rem;
    width: 34rem;
    line-height: 1.35rem;
    overflow: hidden;
    text-overflow: clip;
`