import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledChatInputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    height: 6rem;
    width: 100%;
    border: 0.1rem solid ${theme('forgeLib.neutral.100')};
    border-radius: 0 0 2.4rem 2.4rem;
    /* background-color: red; */
`