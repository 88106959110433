import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledCTAButton = styled.button<{
  iconColor?: string,
  iconBackgroundColor?: string,
  textColor?: string,
  textBackgroundColor?: string,
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  right: 5vh;
  bottom: 5vh;
  width: 6.4rem;
  height: 6.4rem;
  border: 0.1rem solid ${theme('forgeLib.neutral.99')};
  border-radius: 10rem;
  box-sizing: border-box;
  box-shadow: 0.2rem 0.4rem 1rem rgba(64, 144, 232, 0.5);
  color: ${props => props.iconColor || '#FFFFFF'};
  background: ${props => props.iconBackgroundColor || `#007FAD` };
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  &:hover {
    color: ${props => props.textColor || `#007FAD`};
    background-color: ${props => props.textBackgroundColor || '#FFFFFF'};
  }
  z-index: 9999;
`