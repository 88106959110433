import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledTrustCloseIcon = styled.button`
    cursor: pointer;
    /* position: fixed;
    right: 5.5vh;
    bottom: 42vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0 0 27rem;
    /* margin-left: 27rem;
    margin-top: 1.5rem; */
    background: transparent;
    border: none;
    svg {
      font-size: 24px;
      color: #9e9e9e;
    }
`