import styled from 'styled-components';

export const StyledChatMessageContainer = styled.div`
    &.user-chatbubble-flex-parent {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: calc(340px - var(--scrollbar-width));
      /* background-color: red; */
    }
    &.bot-chatbubble-flex-parent {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: flex-start;
      width: calc(340px - var(--scrollbar-width));
      /* color: red; */
    }
`