// eslint-disable-next-line
import './sass/index.scss';

import renderApp from './lib/renderApp';

import App from './components/App';

// export this as a function so it can be unit tested
export const render = (): void => {
  renderApp.withoutAuth(App);
}

render();
